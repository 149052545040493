import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, InlineStack, Modal, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { byMoreCreditInitialValues, getByMoreCreditFormField } from "@/Assets/Mocks/CommonPricing.mock";
import CommonForm from "@/Components/Common/CommonForm";
import { navigate } from "@/Components/Common/NavigationMenu";

export default function BuyMoreCredit(props) {
  const { buyMoreCreditPopup, setBuyMoreCreditPopup, profileData } = props;
  const [buyMoreCrediformValues, setBuyMoreCreditFormValues] = useState(byMoreCreditInitialValues);
  const byMoreCreditFormField = getByMoreCreditFormField();
  const { t } = useTranslation();
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const navigateTo = navigate();

  const cancelBuyMoreCreditPopup = useCallback(() => {
    setBuyMoreCreditPopup(false);
  }, []);

  const submitBuyMoreCredit = useCallback(
    async (e) => {
      setBuyMoreCreditPopup(false);
      const { shopUrl, email } = profileData;
      const storeUrl = shopUrl.split(".myshopify.com")[0];
      const newPlan = {
        name: "Buy More Credit",
        price: e.total,
        return_url: `https://admin.shopify.com/store/${storeUrl}/apps/${process.env.SHOPIFY_APP_URL_FOR_PRICING}/pricing?metaTags=${e.metaTags}&&imageCredit=${e.imageCredit}&&aiImageAltText=${e.aiImageAltText}`,
        test: email?.includes("webrexstudio.com"),
      };

      const response = await fetch.post("plan/addMoreCredit", newPlan);
      const confirmationUrl = response.data?.["appPurchaseOneTimeCreate"].confirmationUrl;

      if (confirmationUrl) {
        const redirectUrl = confirmationUrl.includes("admin.shopify.com")
          ? removeBasePriceURL(confirmationUrl)
          : confirmationUrl;

        navigateTo(
          redirectUrl,
          confirmationUrl.includes("admin.shopify.com") ? { replace: false, target: "host" } : undefined
        );
      }
    },
    [profileData]
  );

  const handlebyMoreCreditChange = useCallback((e) => {
    e.total = (e.imageCredit / 100 + e.metaTags / 10 + e.aiImageAltText / 25).toFixed(2);
    setBuyMoreCreditFormValues(e);
  }, []);

  const submitForm = useCallback((e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const isYearly = ["Premium-Yearly", "Pro-Yearly"].includes(profileData?.recurringPlanId);
  let creditObj = useMemo(() => profileData?.credits);

  const getCreditBadge = (profileCredit, text) => {
    const credit = profileCredit || 0;
    return (
      <Badge fullWidth tone={credit <= 0 ? "critical" : "success"}>
        <Text variant="headingSm" align="center">
          {t(`pricing.${text} Credit`)} : {credit}
        </Text>
      </Badge>
    );
  };

  return (
    <Modal
      open={buyMoreCreditPopup}
      onClose={cancelBuyMoreCreditPopup}
      title={t(`pricing.Purchase plan`)}
      primaryAction={{
        content: t(`pricing.Purchase plan`),
        onAction: submitForm,
      }}
    >
      <Modal.Section>
        <InlineStack gap="200" align="start">
          <BlockStack gap="200">
            {getCreditBadge(
              creditObj?.normal?.imageOptimization,
              `${isYearly ? "Yearly Image" : "Monthly Image"}`
            )}
            {getCreditBadge(creditObj?.purchase?.imageOptimization, "Purchase Image")}
          </BlockStack>
          <BlockStack gap="200">
            {getCreditBadge(creditObj?.normal?.aiMetaTags, `${isYearly ? "Yearly AI" : "Monthly AI"}`)}
            {getCreditBadge(creditObj?.purchase?.aiMetaTags, "Purchase AI")}
          </BlockStack>
          <BlockStack gap="200">
            {getCreditBadge(
              creditObj?.normal?.aiImageAltText,
              `${isYearly ? "Yearly AI AltText" : "Monthly AI AltText"}`
            )}
            {getCreditBadge(creditObj?.purchase?.aiImageAltText, "Purchase AI AltText")}
          </BlockStack>
        </InlineStack>
        <br />
        <CommonForm
          onSubmit={submitBuyMoreCredit}
          formRef={formRef}
          initialValues={buyMoreCrediformValues}
          formFields={byMoreCreditFormField}
          isSave={false}
          noValueChanged={false}
          onFormChange={handlebyMoreCreditChange}
        />
      </Modal.Section>
    </Modal>
  );
}
