import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { initReactI18next } from "react-i18next";
import { useCustomerly } from "react-live-chat-customerly";
import { Routes as ReactRouterRoutes, Route, useLocation } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import { Loading } from "@shopify/polaris";
import i18n from "i18next";
import { onCLS, onFID, onLCP } from "web-vitals";
import { AppNavigationMenu } from "@/Components/Common/NavigationMenu";
import { logger } from "@/Services/Logger/Index";
import { adminEnvCheck, checkPartenerAcc, isAdmin, setSessionStorageItem } from "@/Utils/Index";
import NoAccessStoreDesign from "./Components/Common/NoAccessStoreDesign";
import { ProfileContext } from "./Context/ProfileContext";

const FallbackUIComponent = lazy(() => import("@/Components/Common/FallbackUIComponent"));
const Support = lazy(() => import("@/Components/Common/Support"));
// Import your components using lazy
const ReviewPopup = lazy(() => import("@/Components/Common/ReviewPopup"));
const BackendTesting = lazy(() => import("@/Pages/BackendTesting/BackendTesting"));
const Breadcrumbs = lazy(() => import("@/Pages/Breadcrumbs/Breadcrumbs"));
const BrokenLink = lazy(() => import("@/Pages/BrokenLink/BrokenLink"));
const ExitFrame = lazy(() => import("@/Pages/ExitFrame/ExitFrame"));
const GSCSitemap = lazy(() => import("@/Pages/GoogleSearchConsole/GSCSitemap"));
const Home = lazy(() => import("@/Pages/Home/Home"));
const SingleMetaDetails = lazy(() => import("@/Pages/MetaTags/SingleMetaDetails"));
const MetaTags = lazy(() => import("@/Pages/MetaTags/MetaTags"));
const NotFound = lazy(() => import("@/Pages/NotFound/NotFound"));
const Other = lazy(() => import("@/Pages/Other/Other"));
const Pricing = lazy(() => import("@/Pages/Pricing/Pricing"));
const HowToUseRichSnippet = lazy(() => import("@/Pages/Schema/HowToUseRichSnippet"));
const ProductFAQSnippet = lazy(() => import("@/Pages/Schema/ProductFAQSnippet"));
const RecipeRichSnippet = lazy(() => import("@/Pages/Schema/RecipeRichSnippet"));
const Schema = lazy(() => import("@/Pages/Schema/Schema"));
const VideoRichSnippet = lazy(() => import("@/Pages/Schema/VideoRichSnippet"));
const Settings = lazy(() => import("@/Pages/Settings/Settings"));
const SpeedOptimize = lazy(() => import("@/Pages/SpeedOptimize/SpeedOptimize"));
const TestSnippets = lazy(() => import("@/Pages/TestSnippets/TestSnippets"));
const ImageOptimizer = lazy(() => import("./Pages/Image Optimizer/ImageOptimizer"));
const OnBoarding = lazy(() => import("@/Pages/OnBoarding/OnBoarding"));
const PageIndexDetail = lazy(() => import("./Pages/GoogleIndex/SingleProductDetails/PageIndexDetail"));
const Sitemap = lazy(() => import("@/Pages/Sitemap/Sitemap"));
const SpeedBooster = lazy(() => import("@/Pages/SpeedBooster/SpeedBooster"));
const SeoBooster = lazy(() => import("@/Pages/SeoBooster/SeoBooster"));
const Dashboard = lazy(() => import("@/Pages/Dashboard/Dashboard"));
const Automations = lazy(() => import("@/Pages/Automations/Automations"));
const Feedback = lazy(() => import("@/Pages/Feedback/Feedback"));
const InstantPage = lazy(() => import("@/Pages/InstantPage/InstantPage"));
const GoogleSearchReport = lazy(() => import("@/Pages/GoogleSearchReport/GoogleSearchReport"));
const Review = lazy(() => import("@/Pages/Review/Review"));
const BlogPost = lazy(() => import("@/Pages/BlogPost/BlogPost"));
const SingleBlogPostData = lazy(() => import("@/Pages/BlogPost/SingleBlogPostData"));
const GoogleIndex = lazy(() => import("@/Pages/GoogleIndex/GoogleIndex"));
const AppUninstall = lazy(() => import("@/Components/Common/AppUninstall"));

const Routes = () => {
  const { profileData } = useContext(ProfileContext);
  const [dataSent, setDataSent] = useState(false);
  const [appLanguage, setAppLanguage] = useState();
  let location = useLocation();
  const { load, update } = useCustomerly();

  useEffect(() => {
    update({
      email: profileData?.email,
    });
  }, [location]);

  useEffect(() => {
    if (profileData && !adminEnvCheck(profileData) && process.env.HOTJAR_TRACKING_ID) {
      Hotjar.init(process.env.HOTJAR_TRACKING_ID, process.env.HOTJAR_VERSION);
    }
  }, [profileData]);

  const sendToGoogleAnalytics = (data) => {
    const { name, delta, id, value, rating } = data;
    // console.log(name, delta, id, value, rating);
    if (process.env.ENV == "prod") {
      ReactGA.event(name, {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
        rating: rating,
        isAdmin: Boolean(isAdmin()),
      });
    }
  };

  useEffect(() => {
    const initializeI18n = async () => {
      try {
        await i18n.use(initReactI18next).init();

        let languageToLoad = "en"; // Default to English
        if (!isAdmin() && profileData?.appLanguage) {
          languageToLoad = profileData.appLanguage;
        }
        const languageResource = await import(`@/LanguageJS/${languageToLoad}.js`);
        i18n.addResourceBundle(languageToLoad, "translation", languageResource.default);
        await i18n.changeLanguage(languageToLoad);
        setSessionStorageItem("appLanguage", languageToLoad);
        setAppLanguage(i18n.language);
      } catch (error) {
        logger.error(error);
      }
    };

    initializeI18n();
  }, [profileData?.appLanguage, i18n]);

  useEffect(() => {
    if (!dataSent) {
      onCLS((data) => sendToGoogleAnalytics(data, profileData));
      onLCP((data) => sendToGoogleAnalytics(data, profileData));
      onFID((data) => sendToGoogleAnalytics(data, profileData));
      setDataSent(true);
    }
  }, []);

  var simulateMouseEvent = function (element, eventName, coordX, coordY) {
    element.dispatchEvent(
      new MouseEvent(eventName, {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: coordX,
        clientY: coordY,
        button: 0,
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const theButton = document.getElementById("component");
      if (theButton) {
        var box = theButton.getBoundingClientRect(),
          coordX = box.left + (box.right - box.left) / 2,
          coordY = box.top + (box.bottom - box.top) / 2;
        simulateMouseEvent(theButton, "click", coordX, coordY);
      }
    }, 1000);
  }, []);

  if (profileData && profileData.error) {
    return <FallbackUIComponent />;
  }
  if (checkPartenerAcc(profileData)) {
    return <NoAccessStoreDesign />;
  }

  return (
    <React.Fragment>
      {!profileData && <div className="loading-overlay"></div>}
      <Suspense fallback={<Loading />}>
        {/* <ReviewPopup /> */}
        {/* <Support /> */}
        <AppNavigationMenu />
        {profileData && !profileData.isOnBoardingDone && <OnBoarding />}
        <div id="component"></div>
        {/* <AppUninstall /> */}
        <ReactRouterRoutes>
          <Route path="/" element={<Home />} />
          <Route path="/speed-optimize" element={<SpeedOptimize />} />
          <Route path="/schema" element={<Schema />} />
          <Route path="/schema/recipe" element={<RecipeRichSnippet />} />
          <Route path="/schema/productFAQ" element={<ProductFAQSnippet />} />
          <Route path="/schema/howTo" element={<HowToUseRichSnippet />} />
          <Route path="/schema/video" element={<VideoRichSnippet />} />
          <Route path="/metatags" element={<MetaTags operationType="metatags" />} />
          <Route path="/imagealt" element={<MetaTags operationType="imagealt" />} />
          <Route path="/google-index/:id" element={<PageIndexDetail />} />
          <Route
            path="/metatags/product/:id"
            element={<SingleMetaDetails resource="product" operationType="metatags" />}
          />
          <Route
            path="/metatags/collection/:id"
            element={<SingleMetaDetails resource="collection" operationType="metatags" />}
          />
          <Route
            path="/metatags/article/:id"
            element={<SingleMetaDetails resource="article" operationType="metatags" />}
          />
          <Route
            path="/metatags/page/:id"
            element={<SingleMetaDetails resource="page" operationType="metatags" />}
          />
          <Route
            path="/imagealt/product/:id"
            element={<SingleMetaDetails resource="product" operationType="imagealt" />}
          />
          <Route
            path="/imagealt/collection/:id"
            element={<SingleMetaDetails resource="collection" operationType="imagealt" />}
          />
          <Route
            path="/imagealt/article/:id"
            element={<SingleMetaDetails resource="article" operationType="imagealt" />}
          />
          <Route
            path="/imagealt/page/:id"
            element={<SingleMetaDetails resource="page" operationType="imagealt" />}
          />
          <Route path="/brokenlink/" element={<BrokenLink />} />
          <Route path="/imageoptimization" element={<ImageOptimizer />} />
          <Route path="/breadcrumbs" element={<Breadcrumbs />} />
          <Route path="/test-seo-performance" element={<TestSnippets />} />
          <Route path="/backendTesting" element={<BackendTesting />} />
          <Route path="/exitframe" element={<ExitFrame />} />
          <Route path="/exitframe/:shop" element={<ExitFrame />} />
          <Route path="/pricing" element={<Pricing hasBillingButton={true} />} />
          <Route path="/other" element={<Other />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/backendTesting" element={<BackendTesting />} />
          <Route path="/google-search-console" element={<GSCSitemap />} />
          <Route path="/google-index" element={<GoogleIndex />} />
          <Route path="/instant-page" element={<InstantPage />} />
          <Route path="/google-search-report" element={<GoogleSearchReport />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/speed-booster" element={<SpeedBooster />} />
          <Route path="/seo-booster" element={<SeoBooster />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/automations" element={<Automations />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/review" element={<Review />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/blog-post" element={<BlogPost />} />
          <Route path="/blog-post/:id" element={<SingleBlogPostData />} />
        </ReactRouterRoutes>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
