import React from "react";
import { Badge, BlockStack, Card, Divider, Icon, InlineGrid, InlineStack, Text } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { t } from "i18next";

export default function FreePlan({ plan }) {
  return (
    <div
      style={
        plan?.selected
          ? {
              border: "2px solid #008060",
              borderRadius: "15px",
              boxShadow: "0 0 .3125rem #aee9d1,0 .0625rem .125rem #aee9d1",
              width: "100%",
            }
          : { width: "100%" }
      }
    >
      <Card>
        <BlockStack gap={400}>
          <InlineStack blockAlign="center" align="space-between">
            <Text variant="headingLg" as="h2">
              {plan.name}
            </Text>
            {plan.selected && (
              <Badge tone="success">
                <Text variant="bodyMd">{t(`pricing.Current`)}</Text>
              </Badge>
            )}
          </InlineStack>
          <Divider />
          <InlineStack>
            <InlineGrid
              gap="100"
              columns={{
                xs: "1",
                sm: "2",
                md: "2",
                lg: "3",
                xl: "2",
              }}
            >
              {plan?.features.map((feature, index) => (
                <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                  <Icon size="large" source={CircleTickMajor} tone="success" className="custom-icon" />
                  <Text wrap as="p">
                    {t(`pricing.features.${feature.text}`)}
                  </Text>
                </InlineStack>
              ))}
            </InlineGrid>
            <Text />
          </InlineStack>
        </BlockStack>
      </Card>
    </div>
  );
}
