import { differenceInCalendarMonths, differenceInDays, differenceInYears, formatDistanceToNow } from "date-fns";
import { t } from "i18next";
import { ABLogo, CCLogo, LinkifyLogo, SeoLogo } from "@/Assets/Index";

const freeFeatures = [
  {
    text: "50 Product access",
    icon: "",
  },
  {
    text: "5 AI Meta Tags Credit",
    icon: "",
  },
  {
    text: "25 Al Image Alt Text Credit",
    icon: "",
  },
  {
    text: "50 Image Optimization Credit",
    icon: "",
  },
  {
    text: "Basic Schema",
    icon: "",
  },
  // {
  //   text: "Optimize your google page speed",
  //   icon: "",
  // },
  {
    text: "Manage 404 Redirect (No Limit)",
    icon: "",
  },
  {
    text: "Generate HTML Site Map",
    icon: "",
  },
  {
    text: "SEO Testing tool",
    icon: "",
  },
];

const commonFeatures = [
  {
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.featureData?.videoSchema ? 'All' : 'Premium'} Schema access & 20+ Review App integration`",
    showTranslation: true,
  },
  {
    text: "All Automation and Bulk Operation",
    icon: "",
  },
  {
    text: "5-level storefront category breadcrumbs",
    icon: "",
  },
  {
    text: "Google Index Analysis",
    icon: "",
  },
  {
    text: "XML and HTML sitemap",
    icon: "",
  },
  {
    text: "24*7 Support",
    icon: "",
  },
];

const premiumFeature = [
  {
    icon: "",
    boldFeature: true,
    hideIcon: true,
    intervalText:
      "`In ${plan.billingInterval === 'Year' ? 'yearly' : 'monthly'} plan Get ${plan.billingInterval === 'Year' ? '12 month ' : ''}Credit right away after trial end`",
    hideFeature: "`${plan.billingInterval === 'Month' && plan.price < 29.99 }`",
  },
  {
    text: "Product access",
    icon: "",
    dynamic: true,
    dynamicText: "`${plan.selected && plan?.credits?.metaLimit ? plan.credits.metaLimit :1000 }`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
  },
  {
    text: "AI Meta Tags Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.aiMetaTags ? plan.credits.aiMetaTags : plan.billingInterval==='Year'? 600 : 50 }`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "Al Image Alt Text Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.aiImageAltText ? plan.credits.aiImageAltText : plan.billingInterval==='Year'? 3000 : 250 }`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "Image Optimization Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.imageOptimization ? plan.credits.imageOptimization : plan.billingInterval==='Year'? 12000 :1000 }`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  ...commonFeatures,
];

const advanceFeatures = [
  {
    hideIcon: true,
    boldFeature: true,
    intervalText:
      "`In ${plan.billingInterval === 'Year' ? 'yearly' : 'monthly'} plan Get ${plan.billingInterval === 'Year' ? '12 month ' : ''}Credit right away after trial end`",
    hideFeature: "`${plan.billingInterval === 'Month' && plan.price < 29.99 }`",
  },
  {
    text: "All in premium plus",
    icon: "",
  },
  {
    text: "Product access",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.metaLimit ? plan.credits.metaLimit : getCreditsFromPrice(plan, plan?.monthlyPrice || plan.price, 9.99)?.metaLimit || 3000}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
  },
  {
    text: "AI Meta Tags Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.aiMetaTags ? plan.credits.aiMetaTags : getCreditsFromPrice(plan, plan?.monthlyPrice || plan.price, 9.99)?.metaTag || 150}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "Al Image Alt Text Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.aiImageAltText ? plan.credits.aiImageAltText : getCreditsFromPrice(plan, plan?.monthlyPrice || plan.price, 9.99)?.aiImageAltText || 750}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "Image Optimization Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.imageOptimization ? plan.credits.imageOptimization : getCreditsFromPrice(plan, plan?.monthlyPrice || plan.price, 9.99)?.image || 3000}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "AI Blog Credit",
    icon: "",
    dynamic: true,
    dynamicText:
      "`${plan.selected && plan?.credits?.blogLimit ? plan.credits.blogLimit : getCreditsFromPrice(plan, plan?.monthlyPrice || plan.price, 9.99)?.blogLimit || 6}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
    intervalText: "`/${plan.billingInterval}`",
  },
  {
    text: "Google Index Credit /Month",
    icon: "",
    dynamic: true,
    dynamicText: "`${getGoogleIndexCredit(plan)}`",
    boldDynamicText: "`${plan.billingInterval === 'Year'}`",
  },
  {
    text: "Advance Schema",
    icon: "",
  },
  {
    text: "Homepage image optimization",
    icon: "",
  },
  {
    text: "Generate Blogs by AI",
    icon: "",
  },
  {
    text: "Submit For Google Index",
    icon: "",
  },
  {
    text: "24*7 Support",
    icon: "",
  },
];

const explorerFeatures = [
  {
    text: "All in Free plus",
    icon: "",
  },
  {
    text: "Images for Optimization / year after trial period end",
    icon: "",
    dynamic: true,
    dynamicText: "`${plan.selected && plan?.credits?.imageOptimization ? plan.credits.imageOptimization :3000 }`",
  },
  {
    text: "AI Meta Tags Credit for / year after trial period end",
    icon: "",
    dynamic: true,
    dynamicText: "`${plan.selected && plan?.credits?.aiMetaTags ? plan.credits.aiMetaTags :100 }`",
  },
  {
    text: "AI ImageAltText Credit for / year after trial period end",
    icon: "",
    dynamic: true,
    dynamicText: "`${plan.selected && plan?.credits?.aiImageAltText ? plan.credits.aiImageAltText :25 }`",
  },
  {
    text: "Product Meta tags Optimize, Image alt",
    icon: "",
    dynamic: true,
    dynamicText: "`${plan.selected && plan?.credits?.metaLimit ? plan.credits.metaLimit : 50 }`",
  },
  ...commonFeatures,
];

export const featureList = {
  Free: freeFeatures,
  "Premium-Monthly": premiumFeature,
  "Premium-Yearly": premiumFeature,
  "Premium-Lifetime": premiumFeature,
  "Pro-Lifetime": premiumFeature,
  "Default-feature": premiumFeature,
  Premium100: premiumFeature,
  Pro100: premiumFeature,
  Explorer: explorerFeatures,
  "Pro-Monthly": advanceFeatures,
  "Pro-Yearly": advanceFeatures,
};
export const interval = {
  "Premium-Monthly": "EVERY_30_DAYS",
  "Premium-Yearly": "ANNUAL",
  "Pro-Monthly": "EVERY_30_DAYS",
  "Pro-Yearly": "ANNUAL",
  Explorer: "ANNUAL",
  Default: "",
};

const monthlyCodes = [
  {
    name: "Promotional",
    code: "PE4ACENT",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "OS1HOR",
    type: "percentage",
    value: 10,
  },
  {
    name: "Promotional",
    code: "SO2ULA",
    type: "percentage",
    value: 15,
  },
  {
    name: "Promotional",
    code: "TG3KSD",
    type: "percentage",
    value: 20,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage",
    value: 25,
  },
  {
    name: "Promotional",
    code: "BW5JAK",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "SI6LENPM",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "IN0NERDP",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "AW3AKEPS",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "EN7GHLIG",
    type: "percentage",
    value: 50,
  },
  {
    name: "Promotional",
    code: "GJ4PLO",
    type: "percentage",
    value: 55,
  },
  {
    name: "Promotional",
    code: "XC1VBD",
    type: "percentage",
    value: 60,
  },
];

const yearlyCodes = [
  {
    name: "Promotional",
    code: "BX9QRZ",
    type: "percentage",
    value: 25,
  },
  {
    name: "Promotional",
    code: "LM2JWP",
    type: "percentage",
    value: 30,
  },
  {
    name: "Promotional",
    code: "HD7NFX",
    type: "percentage",
    value: 35,
  },
  {
    name: "Promotional",
    code: "YX8ZPT",
    type: "percentage",
    value: 40,
  },
  {
    name: "Promotional",
    code: "QK5DMR",
    type: "percentage",
    value: 45,
  },
  {
    name: "Promotional",
    code: "FN3BHU",
    type: "percentage",
    value: 50,
  },
  {
    name: "Promotional",
    code: "VU2LQA",
    type: "percentage",
    value: 55,
  },
  {
    name: "Promotional",
    code: "EP9KRN",
    type: "percentage",
    value: 60,
  },
];

export const getPlansData = () => [
  {
    type: "free",
    id: "Free",
    name: "Free",
    price: 0,
    isHidden: false,
    features: [...featureList["Free"]],
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Premium-Monthly",
    name: "Premium",
    price: 9.99,
    isHidden: "hasBillingButton ? !isFirstButtonActive : false", //value true or false
    isPromoInputHidden: false,
    showUpgradePlanPopup: false,
    features: [...featureList["Premium-Monthly"]],
    discounts: [...monthlyCodes],
    trial: {
      days: 7,
    },
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", //it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Premium-Yearly",
    name: "Premium Yearly",
    isHidden: "hasBillingButton ? isFirstButtonActive : false", //value true or false
    monthlyPrice: 9.99,
    price: 119.88,
    isPromoInputHidden: false,
    showUpgradePlanPopup: false,
    features: [...featureList["Premium-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: 6.99,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited)  for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Pro-Monthly",
    name: "Pro",
    isHidden: "hasBillingButton ? !isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? !isFirstButtonActive || (isFirstButtonActive && selectedPlan.billingInterval==='Month' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Monthly') : false", //value true or false
    price: 29.99,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Monthly"]],
    trial: {
      days: 7,
    },
    discounts: [...monthlyCodes],
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Pro-Yearly",
    name: "Pro Yearly",
    isHidden: "hasBillingButton ? isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? isFirstButtonActive || (!isFirstButtonActive && selectedPlan.billingInterval==='Year' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Yearly') : true", //value true or false
    monthlyPrice: 29.99,
    price: 359.88,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: 20.99,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited)  for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Pro-Monthly",
    name: "Pro",
    isHidden: "hasBillingButton ? !isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? !isFirstButtonActive || (isFirstButtonActive && selectedPlan.billingInterval==='Month' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Monthly') : false", //value true or false
    price: 49.99,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Monthly"]],
    trial: {
      days: 7,
    },
    discounts: [...monthlyCodes],
  },
  {
    type: "recurring",
    is_recurring: true,
    billingInterval: "Month", // it could be (Year | Month | Unlimited)  for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "EVERY_30_DAYS",
    id: "Pro-Monthly",
    name: "Pro",
    isHidden: "hasBillingButton ? !isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? !isFirstButtonActive || (isFirstButtonActive && selectedPlan.billingInterval==='Month' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Monthly') : false", //value true or false
    price: 199.99,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Monthly"]],
    trial: {
      days: 7,
    },
    discounts: [...monthlyCodes],
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Pro-Yearly",
    name: "Pro Yearly",
    isHidden: "hasBillingButton ? isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? isFirstButtonActive || (!isFirstButtonActive && selectedPlan.billingInterval==='Year' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Yearly') : true", //value true or false
    monthlyPrice: 49.99,
    price: 599.88,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: 34.99,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
  {
    type: "recurring", //it could be (recurring|onetime)
    is_recurring: true,
    billingInterval: "Year", // it could be (Year | Month | Unlimited) for differentiate plans in database
    intervalLable: "Month", // it for showing label in plan
    interval: "ANNUAL",
    id: "Pro-Yearly",
    name: "Pro Yearly",
    isHidden: "hasBillingButton && selectedPlan?.originalPrice === 2399.88 ? isFirstButtonActive : false", //value true or false
    // "hasBillingButton ? isFirstButtonActive || (!isFirstButtonActive && selectedPlan.billingInterval==='Year' && selectedPlan.planPrice > 14.99 && selectedPlan.id !== 'Advance-Yearly') : true", //value true or false
    monthlyPrice: 199.99,
    price: 2399.88,
    isPromoInputHidden: false,
    showUpgradePlanPopup: true,
    features: [...featureList["Pro-Yearly"]],
    trial: {
      days: 7,
    },
    discounts: [...yearlyCodes],
    initialDiscountPrice: 139.99,
    initialDiscountObject: { type: "percentage", value: 30 },
    discountPercent: 30,
  },
];

export const promocodeFormFields = [
  {
    id: "promocode",
    name: "promocode",
    labelPosition: "right",
    type: "text",
    errormsg: "Promocode is required",
    placeholder: "Enter Promocode",
  },
];

export const initialValues = {
  promocode: "",
};

export const formFieldsCancelReason = [
  {
    id: "cancelReason",
    name: "cancelReason",
    label: "Please Choose a Cancellation Reason",
    nested: "object",
    groupSize: 2,
    section: false,
    subfields: [
      {
        radioId: "noLongerNeeded",
        id: "noLongerNeeded",
        name: "reason",
        label: "No Longer Needed?",
        type: "radio",
      },
      {
        radioId: "dontWork",
        id: "dontWork",
        name: "reason",
        label: "Didn't Meet Expectations",
        type: "radio",
      },
      {
        radioId: "costConcerns",
        id: "costConcerns",
        name: "reason",
        label: "Cost Concerns",
        type: "radio",
      },
      {
        radioId: "technicalIssues",
        id: "technicalIssues",
        name: "reason",
        label: "Technical Issues",
        type: "radio",
      },
      {
        radioId: "foundAlternative",
        id: "foundAlternative",
        name: "reason",
        label: "Found an Alternative",
        type: "radio",
      },
      {
        radioId: "complexity",
        id: "complexity",
        name: "reason",
        label: "Complexity",
        type: "radio",
      },
      {
        radioId: "lackFeatures",
        id: "lackFeatures",
        name: "reason",
        label: "Lack of Features",
        type: "radio",
      },
      {
        radioId: "poorCustomerSupport",
        id: "poorCustomerSupport",
        name: "reason",
        label: "Poor Customer Support",
        type: "radio",
      },
      {
        radioId: "businessClosure",
        id: "businessClosure",
        name: "reason",
        label: "Business Closure or Change",
        type: "radio",
      },
      {
        radioId: "temporaryPause",
        id: "temporaryPause",
        name: "reason",
        label: "Temporary Pause",
        type: "radio",
      },
      {
        radioId: "performanceIssues",
        id: "performanceIssues",
        name: "reason",
        label: "Performance Issues",
        type: "radio",
      },
      {
        radioId: "other",
        id: "other",
        name: "reason",
        label: "Other",
        type: "radio",
      },
      {
        id: "value",
        name: "value",
        label: "Mention Your Specific Reason Here",
        labelPosition: "left",
        type: "text",
      },
    ],
  },
];

const decodeHelpText = (props) => {
  let deviderValue;
  const fieldName = props?.field?.name;

  if (props.field.name === "imageCredit") {
    deviderValue = 100;
  } else if (props.field.name === "metaTags") {
    deviderValue = 10;
  } else {
    deviderValue = 25;
  }
  const countText =
    t(`pricing.Purchase`) +
    " " +
    props?.form?.values?.[fieldName] +
    " " +
    t(`pricing.credits for $`) +
    props?.form?.values?.[fieldName] / deviderValue;

  return countText;
};

export const getByMoreCreditFormField = () => [
  {
    id: "imageCredit",
    name: "imageCredit",
    label: t(`pricing.Image Credit Purchase (1 Image/1¢) (100 Image/$1)`),
    type: "rangeSlider",
    max: 10000,
    step: 50,
    helpTextDynamic: true,
    helpTextFn: decodeHelpText,
  },
  {
    id: "metaTags",
    name: "metaTags",
    label: t(`pricing.Add AI Credit (1 AI Credit/10¢) (10 AI Credit/$1 )`),
    type: "rangeSlider",
    max: 2000,
    step: 10,
    helpTextDynamic: true,
    helpTextFn: decodeHelpText,
  },
  {
    id: "aiImageAltText",
    name: "aiImageAltText",
    label: t(`pricing.Add image altText AI Credit (1 AI Credit/4¢) (25 AI Credit/$1 )`),
    type: "rangeSlider",
    max: 2000,
    step: 25,
    helpTextDynamic: true,
    helpTextFn: decodeHelpText,
  },
  {
    id: "total",
    name: "total",
    label: t(`pricing.Total purchase`),
    type: "number",
    disabled: true,
    prefix: "$",
    helpText: t(`pricing.This credit will never expire. It will expired if you Uninstall our app.`),
  },
];
export const getUpgradePlanFormField = () => [
  {
    id: "planStep",
    name: "planStep",
    type: "rangeSlider",
    label: t(`pricing.Choose plan`),
    max: 4,
    min: 1,
    showOutput: false,
    defaultSuffix: false,
    suffix: `$14.99`,
  },
];
export const cancelReasonInitialValues = {
  cancelReason: {
    reason: "",
    value: "",
  },
};
export const byMoreCreditInitialValues = {
  imageCredit: 100,
  metaTags: 10,
  aiImageAltText: 25,
  total: 3,
};

export const upgradePlanInitialValues = {
  planStep: 1,
};

export const makeValueForPurchaseObj = (firstStepPlanPrice) => {
  const firstPrice = firstStepPlanPrice == 14.99 ? 14.99 : 9.99;
  const priceArray = [firstPrice, 29.99, 49.99, 199.99];
  const findMinimumPrice = priceArray
    .map((item) => (firstStepPlanPrice <= item || item == 199.99 ? item : null))
    .filter((item) => item !== null); // Filter out null values
  const finalObj = findMinimumPrice.reduce((accumulator, price, index) => {
    if (price == 14.99) {
      accumulator[index + 1] = {
        image: 1000,
        metaTag: 50,
        metaLimit: 1000,
        aiImageAltText: 250,
        blogLimit: 2,
        planPrice: price,
        stepLength: findMinimumPrice?.length,
      };
    } else {
      accumulator[index + 1] = {
        image: 1000 + (1000 * (price - 9.99)) / 10,
        metaTag: 50 + (50 * (price - 9.99)) / 10,
        metaLimit: price === 199.99 ? 50000 : 1000 + (1000 * (price - 9.99)) / 10,
        aiImageAltText: 250 + (250 * (price - 9.99)) / 10,
        blogLimit: 2 + (2 * (price - 9.99)) / 10,
        planPrice: price,
        stepLength: findMinimumPrice?.length,
      };
    }
    return accumulator;
  }, {});
  return finalObj;
};

export const getCreditsFromStep = (plan, step, firstStepPlanPrice) => {
  const valueForPurchase = makeValueForPurchaseObj(firstStepPlanPrice);
  if (!valueForPurchase[step]) step = 1;
  if (plan.billingInterval === "Year") {
    let { metaTag, image, aiImageAltText, blogLimit, ...rest } = valueForPurchase[step];
    return {
      metaTag: metaTag * 12,
      image: image * 12,
      aiImageAltText: aiImageAltText * 12,
      blogLimit: blogLimit * 12,
      ...rest,
    };
  } else {
    return valueForPurchase[step];
  }
};

export const findPlanForProductCount = (productCount, firstStepPlanPrice) => {
  const valueForPurchase = makeValueForPurchaseObj(firstStepPlanPrice);
  const selectedPlan = Object.entries(valueForPurchase).find(([price, plan]) => {
    const planMetaLimit = parseInt(plan.metaLimit);
    return planMetaLimit >= productCount;
  });
  return selectedPlan ? selectedPlan[0] : 4;
};

export const getCreditsFromPrice = (plan, planPrice, firstStepPlanPrice) => {
  const valueForPurchase = makeValueForPurchaseObj(firstStepPlanPrice);
  const selectedPlan = Object.entries(valueForPurchase).find(
    ([id, e]) => parseInt(e.planPrice) === parseInt(planPrice)
  );
  return getCreditsFromStep(plan, selectedPlan ? selectedPlan[0] : 1, firstStepPlanPrice);
};

const dateWisePrice = (date, billingInterval) => {
  const day = differenceInDays(new Date(), new Date(date));
  if (day <= 60) {
    return billingInterval === "Year" ? 4.99 : 7.99;
  } else if (day > 60 && day <= 180) {
    return billingInterval === "Year" ? 5.99 : 8.99;
  } else if (day > 180 && day <= 365) {
    return billingInterval === "Year" ? 6.99 : 9.99;
  } else {
    return billingInterval === "Year" ? 6.99 : 9.99;
  }
};

const dateAndPercentWisePrice = (date, plan) => {
  const { billingInterval, monthlyPrice, price } = plan;
  const day = differenceInDays(new Date(), new Date(date));
  let discount = 10;
  if (day <= 60) discount = billingInterval === "Year" ? 50 : 20;
  else if (day > 60 && day <= 180) discount = billingInterval === "Year" ? 40 : 10;
  else if (day > 180 && day <= 365) discount = billingInterval === "Year" ? 30 : 0;
  else discount = billingInterval === "Year" ? 30 : 0;
  const planPrice = billingInterval === "Year" ? monthlyPrice : price;
  return (planPrice - (planPrice * discount) / 100).toFixed(2);
};

export const dateWisePriceObj = (date, plan) => {
  const { billingInterval } = plan;
  const monthlyPrice = plan?.monthlyPrice || plan?.price || 9.99;
  const finalPrice = dateAndPercentWisePrice(date, plan);
  const persent = Math.round(100 - (finalPrice * 100) / monthlyPrice);
  let finalObj = {};
  if (finalPrice !== monthlyPrice) {
    finalObj = {
      initialDiscountPrice: finalPrice,
      initialDiscountObject: { type: "percentage", value: persent },
      discountPercent: persent,
    };
  }
  const price = billingInterval === "Year" ? monthlyPrice * 12 : monthlyPrice;
  if (plan?.id !== "Free") {
    return billingInterval === "Year" ? { price, monthlyPrice, ...finalObj } : { price, ...finalObj };
  } else {
    return {};
  }
};
export const dateWisePersent = (date, isFirstButtonActive) => {
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 9.99);
  return persent > 0 ? persent : false;
};

const makeBanner = (distanceText, persent, bannerKey) => {
  const bannerMsg = `${t(`pricing.dateWiseBanner.${bannerKey}MsgStart`)} ${distanceText}. ${t(
    `pricing.dateWiseBanner.${bannerKey}MsgMiddle`
  )} ${persent}% ${t(`pricing.dateWiseBanner.${bannerKey}MsgEnd`)}`;
  const bannerTitle = `${t(`pricing.dateWiseBanner.${bannerKey}TitleStart`)} ${persent}% ${t(
    `pricing.dateWiseBanner.${bannerKey}TitleEnd`
  )} ${distanceText}.`;
  return { bannerMsg, bannerTitle };
};

export const dateWisePriceBanner = (date, isFirstButtonActive) => {
  const day = differenceInDays(new Date(), new Date(date));
  const distanceText = formatDistanceToNow(new Date(date), { addSuffix: true });
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 9.99);
  const differenceInMonth = differenceInCalendarMonths(new Date(), new Date(date));
  const differenceInYear = differenceInYears(new Date(), new Date(date));
  const finalMonth = differenceInMonth - differenceInYear * 12;
  const monthYearText = `${differenceInYear} ${t("pricing.dateWiseBanner.year and")} ${finalMonth} ${t(
    "pricing.dateWiseBanner.month"
  )}`;
  const bannerTitle = `${t("pricing.dateWiseBanner.veryImportantTitleStart")} ${monthYearText} ${t(
    "pricing.dateWiseBanner.veryImportantTitleEnd"
  )}.`;

  if (persent <= 0) {
    return { bannerMsg: `${t("pricing.dateWiseBanner.generalMsg")}`, bannerTitle };
  } else if (day <= 60) {
    return makeBanner(distanceText, persent, "low");
  } else if (day > 60 && day <= 180) {
    return makeBanner(distanceText, persent, "medium");
  } else if (day > 180 && day <= 365) {
    return makeBanner(distanceText, persent, "important");
  } else {
    const bannerMsg = `${t("pricing.dateWiseBanner.veryImportantMsgStart")}  ${monthYearText} ${t(
      "pricing.dateWiseBanner.ago"
    )}. ${t("pricing.dateWiseBanner.veryImportantMsgMiddle")} ${persent}% ${t(
      "pricing.dateWiseBanner.veryImportantMsgEnd"
    )}`;
    return { bannerMsg, bannerTitle };
  }
};

export const AppList = [
  {
    title: "Webrex SEO: AI, Speed & Schema",
    tagLine: "Optimize your Store by align correct SEO to improve organic ranking, boost visibility",
    link: "https://apps.shopify.com/breadcrumbs-schemas?source=SEO-app",
    image: SeoLogo,
    starRate: 4.9,
    reviweCount: 437,
    popular: true,
    hide: true,
  },
  {
    title: "WebRex Multi Announcement Bar",
    tagLine: "Boost sales with customizable bars for announcements, free shipping, and countdown timers",
    link: "https://apps.shopify.com/announcement-bar-with-slider?source=SEO-app",
    image: ABLogo,
    starRate: 4.9,
    reviweCount: 436,
    hide: true,
    // popular: true,
  },
  {
    title: "Linkify ‑ Backlink SEO Booster",
    tagLine: "Supercharge SEO with top-tier backlinks from high DA sites. Boost rankings, traffic, and authority",
    link: "https://apps.shopify.com/linkify-app?source=SEO-app",
    image: LinkifyLogo,
    starRate: 5.0,
    reviweCount: 8,
    hide: true,
    // popular: true,
  },
  {
    title: "Webrex ‑ Currency Converter",
    tagLine: "Solution for your International Selling that will help to show converted prices, local currency",
    link: "https://apps.shopify.com/currency-converter-11?source=SEO-app",
    image: CCLogo,
    starRate: 4.9,
    reviweCount: 306,
    hide: true,
    // popular: true,
  },
];

export const getGoogleIndexCredit = (plan) => {
  const price = plan?.monthlyPrice ? plan?.monthlyPrice : plan?.price;
  if (price <= 29.99) return 750;
  else if (price <= 49.99) return 1500;
  else return 6000;
};
