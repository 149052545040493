import React from "react";
import { Badge, BlockStack, InlineStack, Text } from "@shopify/polaris";

export default function CreditUsage({ user }) {
  // const targatDate = user?.created < "2024-04-23T12:29:35.214Z" ? "2024-04-23T12:29:35.214Z" : user?.created;

  const getBadge = (key, text) => {
    return (
      <InlineStack gap={200}>
        <Badge tone="attention">{text} :</Badge>
        <Text>
          usage : <b>{user?.credits?.totalUsedCredit?.[key] || 0}</b>
        </Text>
        <Text>
          remaining : <b>{user?.credits?.normal?.[key] || 0}</b>{" "}
        </Text>
      </InlineStack>
    );
  };

  return (
    <BlockStack gap={200}>
      {getBadge("aiMetaTags", "Metatag")}
      {getBadge("aiImageAltText", "Image Alt")}
      {getBadge("imageOptimization", "image optimization")}
      {getBadge("blogLimit", "Blog Limit")}
    </BlockStack>
  );
}
