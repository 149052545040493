import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate as reactUseNavigate, useLocation } from "react-router-dom";
import { NavigationMenu } from "@shopify/app-bridge-react";
import { useNavigate as shopifyUseNavigate } from "@shopify/app-bridge-react";
import { Navigation } from "@shopify/polaris";
import { HomeMinor, OrdersMinor, ProductsMinor } from "@shopify/polaris-icons";
import { getNavigationLinks } from "@/Assets/Mocks/Navigation.mock";
import { localStorage } from "@/Utils/Index";

export const AppNavigationMenu = () => {
  const location = useLocation();

  const NavigationLinks = getNavigationLinks();
  if (process.env.ENV === "dev") {
    NavigationLinks.splice(2, 0, {
      label: "Backend Testing",
      destination: "/backendTesting",
    });
  }

  const menuHierarchy = {
    "/seo-booster": [
      "/breadcrumbs",
      "/metatags",
      "/schema",
      "/brokenlink",
      "/breadcrumbs",
      "/test-seo-performance",
      "/imagealt",
      "/blog-post",
    ],
    "/speed-booster": ["/imageoptimization", "/speed-optimize", "/instant-page"],
    "/google-search-console": ["/sitemap", "/google-index-status", "google-index", "google-search-report"],
  };
  const handleActiveLink = (link, location) => {
    const subpaths = menuHierarchy?.[link?.destination] || [];
    return subpaths?.some((subpath) => location?.pathname?.includes(subpath));
  };

  const navigation = reactUseNavigate();
  const [selected, setSelected] = useState(location.pathname);

  const handleClick = useCallback((item) => {
    navigation(item.destination);
    setSelected(item.destination);
  }, []);

  if (!localStorage()?.getItem("adminAccessToken")) {
    return (
      <NavigationMenu
        navigationLinks={NavigationLinks}
        matcher={(link, location) => handleActiveLink(link, location)}
      />
    );
  }

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={NavigationLinks.map((item) => ({
          ...item,
          onClick: () => handleClick(item),
          icon: HomeMinor,
          selected: selected === item.destination,
        }))}
      />
    </Navigation>
  );
};

export const navigate = () => {
  let navigate;
  if (!localStorage()?.getItem("adminAccessToken")) {
    navigate = shopifyUseNavigate();
  } else {
    navigate = reactUseNavigate();
  }
  return navigate;
};
