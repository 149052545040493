import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Divider, InlineStack, Modal, Text, TextField } from "@shopify/polaris";
import { differenceInCalendarDays, differenceInCalendarMonths } from "date-fns";
import {
  findPlanForProductCount,
  getCreditsFromStep,
  getGoogleIndexCredit,
  getUpgradePlanFormField,
  upgradePlanInitialValues,
} from "@/Assets/Mocks/CommonPricing.mock";
import CommonForm from "@/Components/Common/CommonForm";

export default function UpgradePlanPopup(props) {
  const {
    upgradePopup,
    setUpgradePopup,
    shopifyPlanActive,
    planForActive,
    profileData,
    firstStepPlanPrice,
    selectedPlan,
    trialDays,
  } = props;
  const formRef = useRef();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    planStep: findPlanForProductCount(profileData.productCount || 0, firstStepPlanPrice),
  });
  const [currentPlanObj, setCurrentPlanObj] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [disableFirstPlan, setDisableFirstPlan] = useState(false);
  const [upgradePlanFormField, setUpgradePlanFormField] = useState(getUpgradePlanFormField());

  const cancelPopup = useCallback(() => {
    setUpgradePopup(false);
    setFormValues(upgradePlanInitialValues);
  }, []);

  const upgratePlan = useCallback(async () => {
    let appendUrl = `&&purDisPrs=${discountPrice}&&purOrgPrs=${currentPlanObj.planPrice}`;
    planForActive["purchasedPlanPrice"] = currentPlanObj.planPrice;
    shopifyPlanActive(planForActive, appendUrl);
  }, [planForActive, currentPlanObj, discountPrice]);

  const submitBuyMoreCredit = useCallback(async (e) => {}, []);

  const onFormChange = useCallback(async (values) => {
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
  }, []);

  useEffect(() => {
    const planObj = getCreditsFromStep(planForActive, formValues.planStep, firstStepPlanPrice);
    setCurrentPlanObj(planObj);
    setDiscountPrice(
      planForActive.discountPercent
        ? (planObj.planPrice - (planObj.planPrice * planForActive.discountPercent) / 100).toFixed(2)
        : planForActive.billingInterval === "Year"
        ? (planObj.planPrice - (planObj.planPrice * 20) / 100).toFixed(2)
        : planObj.planPrice
    );
  }, [formValues, planForActive, firstStepPlanPrice]);

  useEffect(() => {
    setUpgradePlanFormField([
      { ...getUpgradePlanFormField()[0], suffix: `$${discountPrice}`, max: currentPlanObj?.stepLength || 4 },
    ]);
  }, [discountPrice, currentPlanObj]);

  const usedCreditText = useCallback(
    (type, objType) => {
      const count = selectedPlan?.billingInterval === "Year" ? 12 : 30;
      const differenceInMonth =
        selectedPlan?.billingInterval === "Year"
          ? differenceInCalendarMonths(new Date(), new Date(selectedPlan?.currentYearStartDate)) + 1
          : differenceInCalendarDays(new Date(), new Date(selectedPlan?.currentMonthStartDate)) + 1;
      const remainingMonth = count - (differenceInMonth % count);
      const allCredit = selectedPlan?.activePlanCredit?.[type] || 0;
      const creditMonthWise = selectedPlan?.id === "Free" ? 0 : (allCredit * remainingMonth) / count;

      const userMinusCredit = profileData?.credits?.minusCredit?.[type] || 0;
      let profileCredit = (profileData?.credits?.normal?.[type] || 0) + userMinusCredit;
      let remainingCredit = parseInt(creditMonthWise - profileCredit || 0);
      const currentPlanCredit = currentPlanObj?.[objType] - remainingCredit;
      if (remainingCredit > 0 && !trialDays && !isNaN(currentPlanCredit))
        return (
          <BlockStack inlineAlign="end">
            <Text tone="subdued">
              {t(`pricing.New plan credit`)}: <b>{currentPlanObj?.[objType]}</b>
            </Text>
            <Text tone="subdued">
              {t(`pricing.Extra used credit in current plan`)}: <b>{remainingCredit}</b>{" "}
            </Text>
            <Text tone="subdued">
              {currentPlanCredit < 0 ? (
                <>
                  {t(`pricing.So in the new plan your credit will deduct`)}: <b>{currentPlanCredit}</b>
                </>
              ) : (
                <>
                  {t(`pricing.So in new plan you will get`)}: <b>{currentPlanCredit}</b>
                </>
              )}
            </Text>
          </BlockStack>
        );
      else return <></>;
    },
    [currentPlanObj, selectedPlan, disableFirstPlan, trialDays]
  );

  return (
    <Modal
      open={upgradePopup}
      onClose={cancelPopup}
      title={t(`pricing.Purchase Plan`)}
      primaryAction={{
        content: t(`pricing.Continue`),
        onAction: upgratePlan,
        disabled: disableFirstPlan,
      }}
      secondaryActions={{
        content: t(`pricing.Cancel`),
        onAction: cancelPopup,
      }}
    >
      <Modal.Section>
        <BlockStack gap="400">
          <InlineStack blockAlign="center" gap="100" align="start">
            <Text>
              {t(`pricing.Your product count is`)} <Badge tone="info">{profileData.productCount || 0}</Badge>{" "}
              {t(`pricing.so your suitable plan is`)}{" "}
            </Text>
            <Badge tone="success">
              <InlineStack blockAlign="center" gap="200" align="start">
                {planForActive.discountPercent && (
                  <Text variant="headingMd">
                    <span className="line-through">${currentPlanObj?.planPrice}</span>
                  </Text>
                )}
                <Text variant="headingMd">${discountPrice}</Text>
              </InlineStack>
            </Badge>
          </InlineStack>
          <CommonForm
            onSubmit={submitBuyMoreCredit}
            formRef={formRef}
            initialValues={formValues}
            formFields={upgradePlanFormField}
            isSave={false}
            noValueChanged={false}
            onFormChange={onFormChange}
          />
          <BlockStack gap="100">
            <Text>
              {t(`pricing.Image Optimization`)} : {currentPlanObj?.image} /&nbsp;
              {t(`pricing.${planForActive.billingInterval}`)}
            </Text>
            {usedCreditText("imageOptimization", "image")}
          </BlockStack>

          <Divider />
          <BlockStack gap="100">
            <Text>
              {t(`pricing.AI Metatags`)} : {currentPlanObj?.metaTag} /&nbsp;
              {t(`pricing.${planForActive.billingInterval}`)}
            </Text>
            {usedCreditText("aiMetaTags", "metaTag")}
          </BlockStack>

          <Divider />
          <BlockStack gap="100">
            <Text>
              {t(`pricing.ImageAltText Credit`)} : {currentPlanObj?.aiImageAltText} /&nbsp;
              {t(`pricing.${planForActive.billingInterval}`)}
            </Text>
            {usedCreditText("aiImageAltText", "aiImageAltText")}
          </BlockStack>

          <Divider />
          <BlockStack gap="100">
            <Text>
              {t(`pricing.Blog Limit`)} : {currentPlanObj?.blogLimit} /&nbsp;
              {t(`pricing.${planForActive.billingInterval}`)}
            </Text>
            {usedCreditText("blogLimit", "blogLimit")}
          </BlockStack>
          <Divider />
          <BlockStack gap="100">
            <Text>
              {t(`pricing.Google Index Credit`)} :
              {getGoogleIndexCredit({ monthlyPrice: currentPlanObj?.planPrice })} /&nbsp;
              {t(`pricing.Month`)}
            </Text>
          </BlockStack>
          <Divider />
          <Text>
            {t(`pricing.Product Limit Access`)} : {currentPlanObj?.metaLimit} {t(`pricing.Products`)}
          </Text>
          <Divider />
          <TextField
            multiline={2}
            autoComplete="off"
            disabled={true}
            prefix={<Text variant="headingMd">{t(`pricing.Grand Total`)}</Text>}
            suffix={
              <InlineStack blockAlign="center" gap="500" align="start">
                {(planForActive.discountPercent || planForActive.billingInterval === "Year") && (
                  <Text variant="headingMd">
                    <span className="line-through">${currentPlanObj?.planPrice}</span>
                  </Text>
                )}
                <InlineStack blockAlign="center" gap="100" align="start">
                  <Text variant="headingMd">${discountPrice}</Text>
                  <p>/&nbsp;{t(`pricing.Month`)}</p>
                </InlineStack>
              </InlineStack>
            }
          />
        </BlockStack>
        {(planForActive.discountPercent || planForActive.billingInterval === "Year") && (
          <InlineStack align="end">
            <Text tone="success">
              {planForActive.discountPercent ? planForActive.discountPercent : 20}%{" "}
              {t(`pricing.Disccount applied`)}
            </Text>
          </InlineStack>
        )}
        {disableFirstPlan && (
          <InlineStack align="end">
            <Text tone="critical" fontWeight="medium">
              {t(`pricing.Oops! you can't upgrade to the same plan.`)}
            </Text>
          </InlineStack>
        )}
      </Modal.Section>
    </Modal>
  );
}
